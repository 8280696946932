import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_600d8997 from 'nuxt_plugin_sentryserver_600d8997' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_0b57cfe2 from 'nuxt_plugin_sentryclient_0b57cfe2' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_portalvue_0abea940 from 'nuxt_plugin_portalvue_0abea940' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_toast_171eccfc from 'nuxt_plugin_toast_171eccfc' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_vuewaitplugin_10853345 from 'nuxt_plugin_vuewaitplugin_10853345' // Source: ./vue-wait-plugin.js (mode: 'all')
import nuxt_plugin_veevalidate_466e7618 from 'nuxt_plugin_veevalidate_466e7618' // Source: ./vee-validate.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_0723e67b from 'nuxt_plugin_cookieuniversalnuxt_0723e67b' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_28404cd3 from 'nuxt_plugin_axios_28404cd3' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_3235fc70 from 'nuxt_plugin_pluginutils_3235fc70' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_77ac3d13 from 'nuxt_plugin_pluginrouting_77ac3d13' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_60dcff3c from 'nuxt_plugin_pluginmain_60dcff3c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_b273079e from 'nuxt_plugin_nuxtsvgsprite_b273079e' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_browserdetectplugin_c8f9cb5e from 'nuxt_plugin_browserdetectplugin_c8f9cb5e' // Source: ./browser-detect-plugin.js (mode: 'client')
import nuxt_plugin_injectsvgspriteclient_44df9f1f from 'nuxt_plugin_injectsvgspriteclient_44df9f1f' // Source: ../node_modules/fourwaves-shared/src/plugins/inject-svg-sprite.client.ts (mode: 'client')
import nuxt_plugin_injectapiservice_653653f1 from 'nuxt_plugin_injectapiservice_653653f1' // Source: ../src/plugins/inject-api-service.ts (mode: 'all')
import nuxt_plugin_validationmessageoverrides_983feb6c from 'nuxt_plugin_validationmessageoverrides_983feb6c' // Source: ../src/plugins/validation-message-overrides.ts (mode: 'all')
import nuxt_plugin_componentsmixins_c817a74c from 'nuxt_plugin_componentsmixins_c817a74c' // Source: ../src/plugins/components-mixins.ts (mode: 'all')
import nuxt_plugin_componentsloader_a580168e from 'nuxt_plugin_componentsloader_a580168e' // Source: ../src/plugins/components-loader.ts (mode: 'all')
import nuxt_plugin_useractivities_0f27b8d8 from 'nuxt_plugin_useractivities_0f27b8d8' // Source: ../src/plugins/user-activities.ts (mode: 'client')
import nuxt_plugin_clientcomponentsloader_4ca4a862 from 'nuxt_plugin_clientcomponentsloader_4ca4a862' // Source: ../src/plugins/client-components-loader.ts (mode: 'client')
import nuxt_plugin_anchorinterceptor_3ead1b4a from 'nuxt_plugin_anchorinterceptor_3ead1b4a' // Source: ../src/plugins/anchor-interceptor.ts (mode: 'client')
import nuxt_plugin_languageswitched_5c71760a from 'nuxt_plugin_languageswitched_5c71760a' // Source: ../src/plugins/language-switched.ts (mode: 'client')
import nuxt_plugin_vuescrollto_3073bf29 from 'nuxt_plugin_vuescrollto_3073bf29' // Source: ../node_modules/fourwaves-shared/src/plugins/vue-scrollto.js (mode: 'all')
import nuxt_plugin_vuedraggable_64c23bd1 from 'nuxt_plugin_vuedraggable_64c23bd1' // Source: ../node_modules/fourwaves-shared/src/plugins/vuedraggable.js (mode: 'all')
import nuxt_plugin_devicequeries_3d3f50c2 from 'nuxt_plugin_devicequeries_3d3f50c2' // Source: ../node_modules/fourwaves-shared/src/plugins/device-queries.ts (mode: 'all')
import nuxt_plugin_appearcustomdirective_0a180481 from 'nuxt_plugin_appearcustomdirective_0a180481' // Source: ../node_modules/fourwaves-shared/src/plugins/appear-custom-directive.ts (mode: 'all')
import nuxt_plugin_tabtrapcustomdirective_344f6860 from 'nuxt_plugin_tabtrapcustomdirective_344f6860' // Source: ../node_modules/fourwaves-shared/src/plugins/tabtrap-custom-directive.ts (mode: 'all')
import nuxt_plugin_intersectcustomdirective_0ffc23ed from 'nuxt_plugin_intersectcustomdirective_0ffc23ed' // Source: ../node_modules/fourwaves-shared/src/plugins/intersect-custom-directive.ts (mode: 'all')
import nuxt_plugin_injectmomenttimezone_6b839a78 from 'nuxt_plugin_injectmomenttimezone_6b839a78' // Source: ../node_modules/fourwaves-shared/src/plugins/inject-moment-timezone.ts (mode: 'all')
import nuxt_plugin_libnuxtclientinitpluginclientc44ee4c4_3747f562 from 'nuxt_plugin_libnuxtclientinitpluginclientc44ee4c4_3747f562' // Source: ./lib.nuxt-client-init.plugin.client.c44ee4c4.js (mode: 'client')
import nuxt_plugin_auth_1dccf5fd from 'nuxt_plugin_auth_1dccf5fd' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_websockets_0d7db020 from 'nuxt_plugin_websockets_0d7db020' // Source: ../src/plugins/auth/websockets.ts (mode: 'all')
import nuxt_plugin_intercom_60f17620 from 'nuxt_plugin_intercom_60f17620' // Source: ../node_modules/fourwaves-shared/src/plugins/auth/intercom.ts (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page-transition","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#603cba"},{"name":"msapplication-config","content":"\u002Ficons\u002Fbrowserconfig.xml?v=2"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Ficons\u002Fapple-touch-icon.png?v=2"},{"rel":"icon","type":"image\u002Fsvg+xml","href":"\u002Ficons\u002Ffavicon.svg?v=2"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ficons\u002Ffavicon-32x32.png?v=2"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ficons\u002Ffavicon-16x16.png?v=2"},{"rel":"manifest","href":"\u002Ficons\u002Fsite.webmanifest?v=2"},{"rel":"mask-icon","href":"\u002Ficons\u002Fsafari-pinned-tab.svg?v=2","color":"#7a6fff"},{"http-equiv":"Content-Security-Policy","content":"upgrade-insecure-requests"}],"script":[{"src":"https:\u002F\u002Fcookie-cdn.cookiepro.com\u002Fconsent\u002F9405d4f7-5281-4bae-9cbf-ee062b5b8cf8\u002FOtAutoBlock.js"},{"src":"https:\u002F\u002Fcookie-cdn.cookiepro.com\u002Fscripttemplates\u002FotSDKStub.js","charset":"UTF-8","data-domain-script":"9405d4f7-5281-4bae-9cbf-ee062b5b8cf8"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_600d8997 === 'function') {
    await nuxt_plugin_sentryserver_600d8997(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_0b57cfe2 === 'function') {
    await nuxt_plugin_sentryclient_0b57cfe2(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_0abea940 === 'function') {
    await nuxt_plugin_portalvue_0abea940(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_171eccfc === 'function') {
    await nuxt_plugin_toast_171eccfc(app.context, inject)
  }

  if (typeof nuxt_plugin_vuewaitplugin_10853345 === 'function') {
    await nuxt_plugin_vuewaitplugin_10853345(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_466e7618 === 'function') {
    await nuxt_plugin_veevalidate_466e7618(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_0723e67b === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_0723e67b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_28404cd3 === 'function') {
    await nuxt_plugin_axios_28404cd3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_3235fc70 === 'function') {
    await nuxt_plugin_pluginutils_3235fc70(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_77ac3d13 === 'function') {
    await nuxt_plugin_pluginrouting_77ac3d13(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_60dcff3c === 'function') {
    await nuxt_plugin_pluginmain_60dcff3c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_b273079e === 'function') {
    await nuxt_plugin_nuxtsvgsprite_b273079e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_browserdetectplugin_c8f9cb5e === 'function') {
    await nuxt_plugin_browserdetectplugin_c8f9cb5e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_injectsvgspriteclient_44df9f1f === 'function') {
    await nuxt_plugin_injectsvgspriteclient_44df9f1f(app.context, inject)
  }

  if (typeof nuxt_plugin_injectapiservice_653653f1 === 'function') {
    await nuxt_plugin_injectapiservice_653653f1(app.context, inject)
  }

  if (typeof nuxt_plugin_validationmessageoverrides_983feb6c === 'function') {
    await nuxt_plugin_validationmessageoverrides_983feb6c(app.context, inject)
  }

  if (typeof nuxt_plugin_componentsmixins_c817a74c === 'function') {
    await nuxt_plugin_componentsmixins_c817a74c(app.context, inject)
  }

  if (typeof nuxt_plugin_componentsloader_a580168e === 'function') {
    await nuxt_plugin_componentsloader_a580168e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_useractivities_0f27b8d8 === 'function') {
    await nuxt_plugin_useractivities_0f27b8d8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientcomponentsloader_4ca4a862 === 'function') {
    await nuxt_plugin_clientcomponentsloader_4ca4a862(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_anchorinterceptor_3ead1b4a === 'function') {
    await nuxt_plugin_anchorinterceptor_3ead1b4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_languageswitched_5c71760a === 'function') {
    await nuxt_plugin_languageswitched_5c71760a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescrollto_3073bf29 === 'function') {
    await nuxt_plugin_vuescrollto_3073bf29(app.context, inject)
  }

  if (typeof nuxt_plugin_vuedraggable_64c23bd1 === 'function') {
    await nuxt_plugin_vuedraggable_64c23bd1(app.context, inject)
  }

  if (typeof nuxt_plugin_devicequeries_3d3f50c2 === 'function') {
    await nuxt_plugin_devicequeries_3d3f50c2(app.context, inject)
  }

  if (typeof nuxt_plugin_appearcustomdirective_0a180481 === 'function') {
    await nuxt_plugin_appearcustomdirective_0a180481(app.context, inject)
  }

  if (typeof nuxt_plugin_tabtrapcustomdirective_344f6860 === 'function') {
    await nuxt_plugin_tabtrapcustomdirective_344f6860(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectcustomdirective_0ffc23ed === 'function') {
    await nuxt_plugin_intersectcustomdirective_0ffc23ed(app.context, inject)
  }

  if (typeof nuxt_plugin_injectmomenttimezone_6b839a78 === 'function') {
    await nuxt_plugin_injectmomenttimezone_6b839a78(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclientc44ee4c4_3747f562 === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclientc44ee4c4_3747f562(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_1dccf5fd === 'function') {
    await nuxt_plugin_auth_1dccf5fd(app.context, inject)
  }

  if (typeof nuxt_plugin_websockets_0d7db020 === 'function') {
    await nuxt_plugin_websockets_0d7db020(app.context, inject)
  }

  if (typeof nuxt_plugin_intercom_60f17620 === 'function') {
    await nuxt_plugin_intercom_60f17620(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
