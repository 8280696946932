
import { Component, Vue, Inject, Prop } from 'nuxt-property-decorator';
import { EventDto } from '../../services';
import { IEventListener } from 'globalpayments-lib';
import UIForm from '@globalpayments/js/types/ui/form';

@Component({})
export default class GlobalPaymentsCreditCard extends Vue {
  @Inject() $validator;

  @Prop({ required: true }) event!: EventDto;

  cardForm: UIForm | null = null;
  tokenizationPromise: Promise<string | null> = Promise.resolve(null);

  async mounted() {
    if (window.GlobalPayments === undefined) {
      try {
        await this.loadScript();
      } catch (error) {
        console.error(error);
        return;
      }
    }

    const gpResponse = await this.$api.getGlobalPaymentsAccessToken(this.event.id);

    window.GlobalPayments.configure({
      accessToken: gpResponse.accessToken,
      apiVersion: gpResponse.apiVersion,
      env: gpResponse.environment === 'TEST' ? 'sandbox' : 'production',
    });

    this.cardForm = window.GlobalPayments.ui.form({
      fields: {
        'card-holder-name': {
          target: '#name-on-card',
        },
        'card-number': {
          target: '#card-number',
        },
        'card-expiration': {
          placeholder: 'MM / YYYY',
          target: '#card-expiration',
        },
        'card-cvv': {
          target: '#card-cvv',
        },
        submit: {
          target: '#submit',
          value: 'Test',
        },
      },
      styles: {
        input: {
          'font-size': '1rem',
          'line-height': '1.5',
          'background-color': '#fff',
          'box-shadow': '0 0 0 #b7c2ff',
          transition: 'border-color 80ms linear',
          'flex-grow': '1',
          width: '100%',
          height: '2.6rem',
          padding: '0 .75rem',
          border: '1px solid #e4e8ef',
          'border-radius': '.5rem',
          margin: '0',
          color: '#101828',
        },
        'input:hover': {
          'border-color': '#9297ff',
        },
        'input:focus': {
          'border-color': '#9297ff',
        },
        'input:focus-visible': {
          outline: 'none',
        },
        'input::placeholder': {
          color: '#98a2b3',
          'line-height': '1',
        },
        '#secure-payment-field-wrapper': {
          display: 'flex',
          'align-items': 'center',
          width: '100%',
          height: '2.75rem',
          color: '#101828',
          'background-color': '#fff',
          'border-radius': '.5rem',
          padding: '0',
          'margin-top': '0.25rem',
          margin: '0',
          background: 'none',
          border: 'none',
        },
      },
    });
  }

  public loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const tag = document.createElement('script');
        const container = document.head || document.body;
        tag.src = 'https://js.globalpay.com/v1/globalpayments.js';

        tag.addEventListener('load', () => {
          resolve();
        });

        tag.addEventListener('error', () => {
          reject(new Error(`Failed to load script with src https://js.globalpay.com/v1/globalpayments.js`));
        });

        container.appendChild(tag);
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateErrorMessage(code: string, message: string) {
    let messageContent: string = message;

    // Common errors
    if (code === 'SYSTEM_ERROR_DOWNSTREAM') {
      messageContent = this.$t('shared.error.server_validation.globalpayments.invalid_exp_month') as string;
    } else if (code === 'INVALID_REQUEST_DATA') {
      if (message === 'cvv contains unexpected data') {
        messageContent = this.$t('shared.error.server_validation.globalpayments.invalid_cvv') as string;
      } else {
        messageContent = this.$t('shared.error.server_validation.globalpayments.invalid_card_number') as string;
      }
    } else if (code === 'MANDATORY_DATA_MISSING') {
      if (message.endsWith('expiry_year')) {
        messageContent = this.$t('shared.error.server_validation.globalpayments.missing_exp_year') as string;
      } else if (message.endsWith('expiry_month')) {
        messageContent = this.$t('shared.error.server_validation.globalpayments.missing_exp_month') as string;
      } else if (message.endsWith('number')) {
        messageContent = this.$t('shared.error.server_validation.globalpayments.missing_card') as string;
      } else {
        messageContent = this.$t('shared.error.server_validation.globalpayments.missing_cvv') as string;
      }
    }

    const displayError = document.getElementById('card-errors');
    if (displayError) displayError.textContent = messageContent;
  }

  public initiatePayment(): Promise<string | null> {
    this.updateErrorMessage('', '');

    return new Promise(resolve => {
      const onSuccess = (resp => {
        cleanup();
        resolve(resp.paymentReference);
      }) as IEventListener;

      const onFailure = (fail => {
        cleanup();
        if (fail.error) this.updateErrorMessage(fail.reasons[0].code, fail.reasons[0].message);
        resolve(null);
      }) as IEventListener;

      const cleanup = () => {
        this.cardForm?.frames['card-number']?.off('token-success', onSuccess);
        this.cardForm?.frames['card-number']?.off('token-error', onFailure);
      };

      if (!this.cardForm?.frames['card-number'] || !this.cardForm?.frames?.submit) {
        resolve(null);
      } else {
        this.cardForm.frames['card-number'].on('token-success', onSuccess);
        this.cardForm.frames['card-number'].on('token-error', onFailure);
        this.cardForm.frames.submit.emit('click');
      }
    });
  }
}
