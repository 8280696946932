import { pick } from 'lodash-es';
import { TranslatableField } from './TranslatableField';
import { FeatureDto } from './FeatureDto';
import { OrganizationCurrency } from './OrganizationDto';

export enum AddOnType {
  PeerReviewsModule = 'peerreviewsmodule',
  VirtualPosterSessions = 'virtualpostersessions',
  VideoRecordings = 'videorecordings',
  UnlimitedSubmissions = 'unlimitedsubmissions',
  PaymentCollection = 'paymentcollection',
}

export interface AddOnDto {
  id: string;
  name: TranslatableField;
  type: AddOnType;
  features: FeatureDto[];
  version: string;
  cost: number;
  currency: OrganizationCurrency;
}

export interface AddOnWithOrganizationStatusDto extends AddOnDto {
  isActiveForOrganization: boolean;
}

export const AddOnIconMap: Record<AddOnType, string> = {
  [AddOnType.PeerReviewsModule]: 'reviewer',
  [AddOnType.VirtualPosterSessions]: 'poster',
  [AddOnType.VideoRecordings]: 'video-recorder',
  [AddOnType.UnlimitedSubmissions]: 'submission',
  [AddOnType.PaymentCollection]: 'bank',
};

export interface AddOnOption {
  id: string;
  name: TranslatableField;
  type: AddOnType;
  features: FeatureDto[];
  cost: number;
  isActiveForOrganization: boolean;
  disabled: boolean;
}

export function prepareAddOnOption(addon: AddOnWithOrganizationStatusDto): AddOnOption {
  return {
    ...pick(addon, ['id', 'name', 'type', 'features', 'cost', 'isActiveForOrganization']),
    disabled: false,
  };
}
