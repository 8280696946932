
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { AuthorDto } from '../../services';
import { UserProfilePicture } from '../users';
import { PresentationElement } from './types';

@Component({
  components: {
    UserProfilePicture,
  },
})
export default class PresentationPresenters extends Vue {
  @Prop({ required: true }) readonly presentation!: PresentationElement;

  private get presenters(): Pick<AuthorDto, 'firstName' | 'lastName' | 'affiliations' | 'userId'>[] {
    const authorsPresenting = this.presentation?.authors?.filter(({ isPresenter }) => isPresenter) ?? [];

    if (authorsPresenting.length) {
      return authorsPresenting;
    }

    const submitter = [
      {
        firstName: this.presentation.submitterFirstName,
        lastName: this.presentation.submitterLastName,
        affiliations: [this.presentation.affiliation],
        userId: this.presentation.userId,
      },
    ];

    return submitter;
  }

  public get presenterProfilePictures(): { firstName: string; lastName: string; userId: string }[] {
    return this.presenters
      .map(({ firstName, lastName, userId }) => ({ firstName, lastName, userId: userId ?? '' }))
      .filter((_, index) => index < 2);
  }

  public get presenterFirst(): string {
    const presenter = this.presenters.find(Boolean);
    return presenter ? `${presenter.firstName} ${presenter.lastName}` : '';
  }

  public get presenterOthers(): string | null {
    const count = Math.max(this.presenters.length - 1, 0);
    return count > 0 ? this.$tc('shared.presentations.and_count_others', count) : null;
  }

  public get affiliations(): string[] {
    const affiliations = this.presenters.flatMap(presenter => presenter.affiliations);
    return [...new Set(affiliations)];
  }

  public get affiliationFirst(): string {
    return this.affiliations.find(Boolean) ?? '';
  }

  public get affiliationOthers(): string | null {
    const count = Math.max(this.affiliations.length - 1, 0);
    return count > 0 ? this.$tc('shared.presentations.and_count_others', count) : null;
  }
}
