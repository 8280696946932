
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import {
  type CardFieldsOnApproveData,
  loadScript,
  type OnCancelledActions,
  type PayPalCardFieldsComponent,
  type PayPalCardFieldsIndividualField,
  type PayPalCardFieldsStateObject,
} from '@paypal/paypal-js';
import { BillingInfoDto, EventDto, isSuccess, isValidationError, parseCardFieldsSubmitError } from '../../services';
import InputText from '../design-system/input/input-text/InputText.vue';
import { TranslateResult } from '../../types/i18n';
import { hexToHSL } from '../../helpers';

@Component({ components: { InputText } })
export default class PayPalCreditCard extends Vue {
  @Prop({ required: true }) readonly nameOnCard!: string | null;
  @Prop({ type: String, required: true }) readonly paypalMerchantId!: string;
  @Prop({ type: String, required: true }) readonly currency!: string;
  @Prop({ type: Number, required: true }) readonly checkoutTotal!: number;
  @Prop({ type: Object, required: true }) readonly event!: EventDto;
  @Prop({ required: true }) billingInfo!: BillingInfoDto;
  @Prop(Boolean) readonly disabled!: boolean;

  orderId: string | null = null;
  cardFields: PayPalCardFieldsComponent | null = null;
  cardNameContainer = 'paypal-card-name-field-container';
  cardNameField: PayPalCardFieldsIndividualField | null = null;

  cardNumberContainer = 'paypal-card-number-field-container';
  cardNumberField: PayPalCardFieldsIndividualField | null = null;

  cardExpiryField: PayPalCardFieldsIndividualField | null = null;
  cardExpiryContainer = 'paypal-card-expiry-field-container';

  cardCvvField: PayPalCardFieldsIndividualField | null = null;
  cardCvvContainer = 'paypal-card-cvv-field-container';

  genericCardErrorMessage: string | TranslateResult = '';
  cardNameErrorMessage: string | TranslateResult = '';
  cardNumberErrorMessage: string | TranslateResult = '';
  cardExpiryErrorMessage: string | TranslateResult = '';
  cardCvvErrorMessage: string | TranslateResult = '';

  @Watch('disabled')
  onDisabledChanged(disabled: boolean) {
    if (disabled) {
      this.cardNameField?.setAttribute('disabled', 'disabled');
      this.cardNumberField?.setAttribute('disabled', 'disabled');
      this.cardExpiryField?.setAttribute('disabled', 'disabled');
      this.cardCvvField?.setAttribute('disabled', 'disabled');
    } else {
      this.cardNameField?.removeAttribute('disabled');
      this.cardNumberField?.removeAttribute('disabled');
      this.cardExpiryField?.removeAttribute('disabled');
      this.cardCvvField?.removeAttribute('disabled');
    }
  }

  async mounted() {
    const paypalNamespace = await loadScript({
      clientId: this.$config.paypalClientId,
      dataPartnerAttributionId: this.$config.paypalBnCode,
      environment: this.$config.paypalEnvironment,
      merchantId: this.paypalMerchantId,
      intent: 'capture',
      commit: false,
      vault: false,
      integrationDate: '2020-07-01',
      currency: this.currency,
      locale: this.$i18n.locale === 'en' ? 'en_US' : 'fr_CA',
      components: ['card-fields'],
    });

    if (!paypalNamespace || !paypalNamespace.CardFields) return;

    // Available styles - https://developer.paypal.com/docs/checkout/advanced/customize/card-field-style/
    this.cardFields = paypalNamespace.CardFields({
      style: {
        input: {
          'font-size': '1rem',
          'line-height': '1.5',
          transition: 'border-color 80ms linear',
          // @ts-ignore - valid style, incomplete type definition
          height: '2.75rem',
          padding: '0 .75rem',
          border: '1px solid #e4e8ef',
          'border-radius': '.5rem',
          color: '#101828',
          'font-weight': '450',
        },
        body: {
          padding: '0',
        },
        'input:hover': {
          // @ts-ignore - valid style, incomplete type definition
          border: `1px solid ${this.mainColorHsl(70) || '#9297ff'}`,
          'box-shadow': 'none',
        },
        'input:focus': {
          // @ts-ignore - valid style, incomplete type definition
          border: `1px solid ${this.mainColorHsl(70) || '#9297ff'}`,
          'box-shadow': 'none',
        },
        'input.invalid': {
          color: '#101828',
          // @ts-ignore - valid style, incomplete type definition
          border: '1px solid #f37b95',
          'box-shadow': 'none',
        },
        'input.invalid:hover': {
          // @ts-ignore - valid style, incomplete type definition
          border: '1px solid #f37b95',
          'box-shadow': 'none',
        },
        'input.invalid:focus': {
          // @ts-ignore - valid style, incomplete type definition
          border: '1px solid #f37b95',
          'box-shadow': 'none',
        },
        'input:disabled': {
          color: '#98a2b3',
          // @ts-ignore - valid style, incomplete type definition
          background: '#fcfcfd',
          'box-shadow': 'none',
        },
        'input:disabled:hover': {
          // @ts-ignore - valid style, incomplete type definition
          border: '1px solid #e4e8ef',
          'box-shadow': 'none',
        },
        'input.card-field-number.display-icon': {
          'padding-left': 'calc(1.2rem + 50px) !important',
        },
      },
      createOrder: () => this.createCardFieldsOrder(),
      onApprove: data => this.onApprove(data),
      onError: error => this.onError(error),
    });

    if (this.cardFields?.isEligible()) {
      const cardNameElement = document.getElementById(this.cardNameContainer);
      if (cardNameElement) {
        this.cardNameField = this.cardFields.NameField({
          placeholder: '',
          inputEvents: {
            onBlur: data => this.updateCardNameValidationState(data),
          },
        });
        this.cardNameField.render(cardNameElement);
      }

      const cardNumberElement = document.getElementById(this.cardNumberContainer);
      if (cardNumberElement) {
        this.cardNumberField = this.cardFields.NumberField({
          placeholder: '',
          inputEvents: {
            onBlur: data => this.updateCardNumberValidationState(data),
          },
        });
        this.cardNumberField.render(cardNumberElement);
      }

      const cardExpiryElement = document.getElementById(this.cardExpiryContainer);
      if (cardExpiryElement) {
        this.cardExpiryField = this.cardFields.ExpiryField({
          placeholder: '',
          inputEvents: {
            onBlur: data => this.updateCardExpiryValidationState(data),
          },
        });
        this.cardExpiryField.render(cardExpiryElement);
      }

      const cardCvvElement = document.getElementById(this.cardCvvContainer);
      if (cardCvvElement) {
        this.cardCvvField = this.cardFields.CVVField({
          placeholder: '',
          inputEvents: {
            onBlur: data => this.updateCardCvvValidationState(data),
          },
        });
        this.cardCvvField.render(cardCvvElement);
      }
    }
  }

  public async createCardFieldsOrder() {
    const response = await this.$api.createPaypalOrder({
      paypalMerchantId: this.paypalMerchantId,
      eventId: this.event.id,
      totalAmount: this.checkoutTotal,
      currency: this.currency,
      source: 'cardfields',
    });

    if (isSuccess(response)) {
      return response;
    } else if (isValidationError(response)) {
      this.feedErrorBag(response);
      return '';
    } else {
      this.$toast.error(this.$t('shared.error.server_validation.paypal.order_error') as string);
      return '';
    }
  }

  public async initiatePayment(): Promise<string | null> {
    if (!this.cardFields) return null;
    this.orderId = null;

    this.updateGenericCardErrorMessage('');
    const state = await this.cardFields.getState();
    if (!state.isFormValid) {
      this.updateCardNameValidationState(state);
      this.updateCardNumberValidationState(state);
      this.updateCardCvvValidationState(state);
      this.updateCardExpiryValidationState(state);
      this.scrollToUnregisteredErrors();
      return null;
    }

    try {
      // @ts-ignore-line - this is a valid, but Typescript doesn't know it
      await this.cardFields.submit({
        billingAddress: {
          streetAddress: this.billingInfo.civicAddress.addressLine1,
          extendedAddress: '',
          region: this.billingInfo.civicAddress.stateProvince,
          locality: this.billingInfo.civicAddress.city,
          postalCode: this.billingInfo.civicAddress.postalCode,
          countryCode: this.billingInfo.civicAddress.countryRegion,
        },
      });

      return new Promise(resolve => {
        const delayInMiliseconds = 1000;
        const maxTickCount = 90;
        let tick = 0;

        const interval = setInterval(() => {
          if (this.orderId) {
            clearInterval(interval);
            return resolve(this.orderId);
          }

          if (tick >= maxTickCount) {
            clearInterval(interval);
            return resolve(null);
          }

          tick++;
        }, delayInMiliseconds);
      });
    } catch (error) {
      let errorMessage = parseCardFieldsSubmitError(error);
      if (!errorMessage) {
        console.error('There was an error with card fields.', error);
        errorMessage = this.$t('shared.error.server_validation.paypal.processing_error') as string;
      }
      this.updateGenericCardErrorMessage(errorMessage);
      return null;
    }
  }

  public updateGenericCardErrorMessage(message: string) {
    this.genericCardErrorMessage = message;
  }

  public updateCardNameValidationState(state: PayPalCardFieldsStateObject) {
    const hasError = state.errors.includes('INVALID_NAME');
    this.cardNameErrorMessage = hasError ? this.$t('shared.error.server_validation.paypal.invalid_name') : '';
    this.updateValidationState(this.cardNameField, hasError);
  }

  public updateCardNumberValidationState(state: PayPalCardFieldsStateObject) {
    const hasError = state.errors.includes('INVALID_NUMBER') || state.errors.includes('INELIGIBLE_CARD_VENDOR');
    this.cardNumberErrorMessage = hasError ? this.$t('shared.error.server_validation.paypal.invalid_number') : '';
    this.updateValidationState(this.cardNumberField, hasError);
  }

  public updateCardExpiryValidationState(state: PayPalCardFieldsStateObject) {
    const hasError = state.errors.includes('INVALID_EXPIRY');
    this.cardExpiryErrorMessage = hasError ? this.$t('shared.error.server_validation.paypal.invalid_expiry') : '';
    this.updateValidationState(this.cardExpiryField, hasError);
  }

  public updateCardCvvValidationState(state: PayPalCardFieldsStateObject) {
    const hasError = state.errors.includes('INVALID_CVV');
    this.cardCvvErrorMessage = hasError ? this.$t('shared.error.server_validation.paypal.invalid_cvv') : '';
    this.updateValidationState(this.cardCvvField, hasError);
  }

  private updateValidationState(field: PayPalCardFieldsIndividualField | null, hasError: boolean) {
    if (hasError) {
      field?.removeClass('valid');
      field?.addClass('invalid');
    } else {
      field?.removeClass('invalid');
      field?.addClass('valid');
    }
  }

  public onApprove(data: CardFieldsOnApproveData) {
    this.orderId = data.orderID;
  }

  public onCancel(_1: Record<string, unknown>, _2: OnCancelledActions) {
    this.orderId = null;
  }

  public onError(_: Record<string, unknown>) {
    this.orderId = null;
  }

  public mainColorHsl(luminance: number) {
    if (!this.event.primaryColor || this.event.primaryColor === '#6245EF') return null;
    return `hsl(${hexToHSL(this.event.primaryColor)}, ${luminance}%)`;
  }
}
