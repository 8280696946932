import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19af2d80 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _20530d9a = () => interopDefault(import('../src/pages/redirection.vue' /* webpackChunkName: "pages/redirection" */))
const _b8d3170a = () => interopDefault(import('../src/pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _d30c4bfe = () => interopDefault(import('../src/pages/_slug/schedule/index.vue' /* webpackChunkName: "pages/_slug/schedule/index" */))
const _1d16284a = () => interopDefault(import('../src/pages/_slug/participants/index.vue' /* webpackChunkName: "pages/_slug/participants/index" */))
const _13ffffa0 = () => interopDefault(import('../src/pages/_slug/registration.vue' /* webpackChunkName: "pages/_slug/registration" */))
const _51764e23 = () => interopDefault(import('../src/pages/_slug/registration/index.vue' /* webpackChunkName: "pages/_slug/registration/index" */))
const _5873fe74 = () => interopDefault(import('../src/pages/_slug/registration/confirmation.vue' /* webpackChunkName: "pages/_slug/registration/confirmation" */))
const _7d077de8 = () => interopDefault(import('../src/pages/_slug/registration/billing.vue' /* webpackChunkName: "pages/_slug/registration/billing" */))
const _0eb7eb3d = () => interopDefault(import('../src/pages/_slug/abstracts/index.vue' /* webpackChunkName: "pages/_slug/abstracts/index" */))
const _2dba8f1a = () => interopDefault(import('../src/pages/_slug/submission.vue' /* webpackChunkName: "pages/_slug/submission" */))
const _5971dcb6 = () => interopDefault(import('../src/pages/_slug/submission/index.vue' /* webpackChunkName: "pages/_slug/submission/index" */))
const _056d16fe = () => interopDefault(import('../src/pages/_slug/submission/confirmation.vue' /* webpackChunkName: "pages/_slug/submission/confirmation" */))
const _8e631242 = () => interopDefault(import('../src/pages/_slug/submission/billing.vue' /* webpackChunkName: "pages/_slug/submission/billing" */))
const _0b2b668f = () => interopDefault(import('../src/pages/_slug/errors/_code.vue' /* webpackChunkName: "pages/_slug/errors/_code" */))
const _070868a9 = () => interopDefault(import('../src/pages/_slug/schedule/_id.vue' /* webpackChunkName: "pages/_slug/schedule/_id" */))
const _40ac7cd0 = () => interopDefault(import('../src/pages/_slug/pages/_id.vue' /* webpackChunkName: "pages/_slug/pages/_id" */))
const _7074959c = () => interopDefault(import('../src/pages/_slug/participants/_id.vue' /* webpackChunkName: "pages/_slug/participants/_id" */))
const _665cce36 = () => interopDefault(import('../src/pages/_slug/abstracts/_id.vue' /* webpackChunkName: "pages/_slug/abstracts/_id" */))
const _9fe82832 = () => interopDefault(import('../src/pages/_slug/abstracts/_id/poster.vue' /* webpackChunkName: "pages/_slug/abstracts/_id/poster" */))
const _72f994fc = () => interopDefault(import('../src/pages/_slug/abstracts/_id/slides.vue' /* webpackChunkName: "pages/_slug/abstracts/_id/slides" */))
const _4eb8ea36 = () => interopDefault(import('../src/pages/_slug/abstracts/_id/figures.vue' /* webpackChunkName: "pages/_slug/abstracts/_id/figures" */))
const _76d7e25e = () => interopDefault(import('../src/pages/_slug/abstracts/_id/video.vue' /* webpackChunkName: "pages/_slug/abstracts/_id/video" */))
const _5c706e08 = () => interopDefault(import('../src/pages/_slug/_.vue' /* webpackChunkName: "pages/_slug/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'higher-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/fr",
    component: _19af2d80,
    name: "index___fr"
  }, {
    path: "/redirection",
    component: _20530d9a,
    name: "redirection___en"
  }, {
    path: "/fr/redirection",
    component: _20530d9a,
    name: "redirection___fr"
  }, {
    path: "/",
    component: _19af2d80,
    name: "index___en"
  }, {
    path: "/fr/:slug",
    component: _b8d3170a,
    name: "slug___fr"
  }, {
    path: "/fr/:slug/horaire",
    component: _d30c4bfe,
    name: "slug-schedule___fr"
  }, {
    path: "/fr/:slug/participants",
    component: _1d16284a,
    name: "slug-participants___fr"
  }, {
    path: "/fr/:slug/registration",
    component: _13ffffa0,
    children: [{
      path: "/fr/:slug/inscription",
      component: _51764e23,
      name: "slug-registration___fr"
    }, {
      path: "/fr/:slug/inscription/confirmation",
      component: _5873fe74,
      name: "slug-registration-confirmation___fr"
    }, {
      path: "/fr/:slug/inscription/facturation",
      component: _7d077de8,
      name: "slug-registration-billing___fr"
    }]
  }, {
    path: "/fr/:slug/resumes",
    component: _0eb7eb3d,
    name: "slug-abstracts___fr"
  }, {
    path: "/fr/:slug/submission",
    component: _2dba8f1a,
    children: [{
      path: "/fr/:slug/soumission",
      component: _5971dcb6,
      name: "slug-submission___fr"
    }, {
      path: "/fr/:slug/soumission/confirmation",
      component: _056d16fe,
      name: "slug-submission-confirmation___fr"
    }, {
      path: "/fr/:slug/soumission/facturation",
      component: _8e631242,
      name: "slug-submission-billing___fr"
    }]
  }, {
    path: "/fr/:slug/errors/:code?",
    component: _0b2b668f,
    name: "slug-errors-code___fr"
  }, {
    path: "/fr/:slug/horaire/:id",
    component: _070868a9,
    name: "slug-schedule-id___fr"
  }, {
    path: "/fr/:slug/pages/:id?",
    component: _40ac7cd0,
    name: "slug-pages-id___fr"
  }, {
    path: "/fr/:slug/participants/:id",
    component: _7074959c,
    name: "slug-participants-id___fr"
  }, {
    path: "/fr/:slug/resumes/:id",
    component: _665cce36,
    name: "slug-abstracts-id___fr",
    children: [{
      path: "/fr/:slug/resumes/:id/affiche",
      component: _9fe82832,
      name: "slug-abstracts-id-poster___fr"
    }, {
      path: "/fr/:slug/resumes/:id/diapositives",
      component: _72f994fc,
      name: "slug-abstracts-id-slides___fr"
    }, {
      path: "/fr/:slug/resumes/:id/figures",
      component: _4eb8ea36,
      name: "slug-abstracts-id-figures___fr"
    }, {
      path: "/fr/:slug/resumes/:id/video",
      component: _76d7e25e,
      name: "slug-abstracts-id-video___fr"
    }]
  }, {
    path: "/fr/:slug/*",
    component: _5c706e08,
    name: "slug-all___fr"
  }, {
    path: "/:slug",
    component: _b8d3170a,
    name: "slug___en"
  }, {
    path: "/:slug/abstracts",
    component: _0eb7eb3d,
    name: "slug-abstracts___en"
  }, {
    path: "/:slug/participants",
    component: _1d16284a,
    name: "slug-participants___en"
  }, {
    path: "/:slug/registration",
    component: _13ffffa0,
    children: [{
      path: "/:slug/registration",
      component: _51764e23,
      name: "slug-registration___en"
    }, {
      path: "/:slug/registration/billing",
      component: _7d077de8,
      name: "slug-registration-billing___en"
    }, {
      path: "/:slug/registration/confirmation",
      component: _5873fe74,
      name: "slug-registration-confirmation___en"
    }]
  }, {
    path: "/:slug/schedule",
    component: _d30c4bfe,
    name: "slug-schedule___en"
  }, {
    path: "/:slug/submission",
    component: _2dba8f1a,
    children: [{
      path: "/:slug/submission",
      component: _5971dcb6,
      name: "slug-submission___en"
    }, {
      path: "/:slug/submission/billing",
      component: _8e631242,
      name: "slug-submission-billing___en"
    }, {
      path: "/:slug/submission/confirmation",
      component: _056d16fe,
      name: "slug-submission-confirmation___en"
    }]
  }, {
    path: "/:slug/abstracts/:id",
    component: _665cce36,
    name: "slug-abstracts-id___en",
    children: [{
      path: "/:slug/abstracts/:id/figures",
      component: _4eb8ea36,
      name: "slug-abstracts-id-figures___en"
    }, {
      path: "/:slug/abstracts/:id/poster",
      component: _9fe82832,
      name: "slug-abstracts-id-poster___en"
    }, {
      path: "/:slug/abstracts/:id/slides",
      component: _72f994fc,
      name: "slug-abstracts-id-slides___en"
    }, {
      path: "/:slug/abstracts/:id/video",
      component: _76d7e25e,
      name: "slug-abstracts-id-video___en"
    }]
  }, {
    path: "/:slug/errors/:code?",
    component: _0b2b668f,
    name: "slug-errors-code___en"
  }, {
    path: "/:slug/pages/:id?",
    component: _40ac7cd0,
    name: "slug-pages-id___en"
  }, {
    path: "/:slug/participants/:id",
    component: _7074959c,
    name: "slug-participants-id___en"
  }, {
    path: "/:slug/schedule/:id",
    component: _070868a9,
    name: "slug-schedule-id___en"
  }, {
    path: "/:slug/*",
    component: _5c706e08,
    name: "slug-all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
