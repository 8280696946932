import { formatMoney, formatPennies } from '../services';

export interface MoneyMixins {
  formatPennies(amount: number, currency?: string): string;
  formatMoney(amount: number, currency?: string): string;
}

export const moneyMixins: MoneyMixins = {
  formatPennies(amount, currency) {
    if (!currency) currency = this.$store.state.events.currency as string;
    return formatPennies(amount, currency);
  },
  formatMoney(amount, currency) {
    if (!currency) currency = this.$store.state.events.currency as string;
    return formatMoney(amount, currency);
  },
};
