import { AddOnType } from './../AddOnDto';
import { BillingInfoDto } from './../BillingInfoDto';
import { PackageTier } from './../PackageDto';
import { PaymentMethod, TransactionStatus } from './../TransactionDto';
import { TranslatableField } from '../TranslatableField';
import { UserDto } from './../UserDto';
import { OrganizationCurrency } from './../OrganizationDto';

export interface PackageUpgradeTransactionItemDto {
  id: string;
  tier: PackageTier;
  cost: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
  fee: number;
}

export interface AddOnUpgradeTransactionItemDto {
  id: string;
  type: AddOnType;
  cost: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
}

export enum UpgradeTransactionState {
  PaymentPending = 'paymentpending',
  PaymentMissing = 'paymentmissing',
  Overdue = 'overdue',
  Canceled = 'canceled',
  Paid = 'paid',
}

export interface UpgradeTransactionDto {
  id: string;
  invoiceNumber: string;
  addOnItems: AddOnUpgradeTransactionItemDto[];
  packageItem: PackageUpgradeTransactionItemDto | null;
  creditItem: PackageUpgradeTransactionItemDto | null;
  organizationName: TranslatableField;
  status: TransactionStatus;
  state: UpgradeTransactionState;
  paymentMethod: PaymentMethod;
  currency: OrganizationCurrency;
  gst: number;
  qst: number;
  hst: number;
  subTotal: number;
  total: number;
  purchasedBy: UserDto;
  billingInfo: BillingInfoDto;
  purchaseOrderNumber: string | null;
  creationDate: string;
  dueDate: string;
  gracePeriodEndDate: string;
}
