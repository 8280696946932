import { EventDto } from 'fourwaves-shared';
import { Component, namespace, Vue } from 'nuxt-property-decorator';

const EventsModule = namespace('events');
const HeaderModule = namespace('header');

@Component
export default class HeaderMixin extends Vue {
  @EventsModule.Getter currentEvent!: EventDto;
  @HeaderModule.Action setHeaderVisibility!: (isVisible: boolean) => void;

  headerObserver: any = null;

  mounted() {
    if ('IntersectionObserver' in window) {
      this.headerObserver = new IntersectionObserver(entry => {
        this.setHeaderVisibility(entry[0].isIntersecting);
      });

      this.headerObserver.observe(this.$el);
    }
  }

  beforeDestroy() {
    if (this.headerObserver) {
      this.headerObserver.unobserve(this.$el);
    }
  }
}
