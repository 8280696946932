import moment from 'moment-timezone';

export function isUtcNowInRange(
  startDateTime: string | null | undefined,
  endDateTime: string | null | undefined,
  inclusivity: '[]' | '()' | '[)' | '(]' | undefined = '[]',
  datetime = '',
): boolean {
  startDateTime = startDateTime || '0001-01-01';
  endDateTime = endDateTime || '2060-01-01';

  const now = datetime ? moment.utc(datetime) : moment.utc();
  return now.isBetween(
    moment.utc(startDateTime!),
    moment.utc(endDateTime!),
    undefined,
    // inclusivity is really important
    // https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
    inclusivity,
  );
}

export function isValidDate(date: unknown): date is Date {
  return date instanceof Date && isFinite(Number(date));
}
