import type { SubmissionScope } from './PageDto';
import { ReviewStatus } from './ReviewStatus';
import { FormType } from './FormType';
import { FormEntryStatus } from './FormEntryDto';

export enum FilteringType {
  ContainsAll = 'ContainsAll',
  ContainsAny = 'ContainsAny',
}

export enum FlagFilter {
  UnpaidItems = 'UnpaidItems',
  IsMissingRequiredFields = 'IsMissingRequiredFields',
  NotRegistered = 'NotRegistered',
  PendingPayment = 'PendingPayment',
  InConflict = 'InConflict',
}

export enum StatusFilter {
  Registered = 'Registered',
  Submitted = 'Submitted',
  OnWaitlist = 'OnWaitlist',
  Withdrawn = 'Withdrawn',
  Scheduled = 'Scheduled',
}

export interface GetFormEntriesModel {
  formId: string;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  ascending?: string;
  descending?: string;
  thenBy?: string;
  authorUserId?: string;
  subsetIds?: string[];
  excludedIds?: string[];
  formFieldChoiceIds?: string[];
  unassignedFormFieldIds?: string[];
  assignedFormFieldIds?: string[];
  reviewStatusFilters?: string[];
  filterCurrentUserReviews?: boolean;
  flagFilters?: string[];
  statusFilters?: StatusFilter[];
  withSubmissionFilters?: string[] | boolean[];
  submissionDecisionsFilter?: string[];
  sessionIds?: string[];
  submissionDecisionsFilteringType?: FilteringType;
  filteringType?: FilteringType;
  isPublishedFilter?: boolean;
  from?: string | null;
  to?: string | null;
  submissionScope?: SubmissionScope | null;
  isPresentingNow?: boolean;
  isBookmarked?: boolean;
  isVirtualPoster?: boolean;
  isOnline?: boolean;
}

export type MetaDataFilter = {
  label: string;
  typeLabel: string;
  value: ReviewStatus | FlagFilter | StatusFilter;
  types: FormType[];
};

export const ReviewStatusFilters: MetaDataFilter[] = [
  {
    typeLabel: 'events.tables.review_status',
    value: ReviewStatus.Unassigned,
    label: 'events.reviews.statuses.unassigned',
    types: [FormType.Review],
  },
  {
    typeLabel: 'events.tables.review_status',
    value: ReviewStatus.Pending,
    label: 'events.reviews.statuses.pending',
    types: [FormType.Review],
  },
  {
    typeLabel: 'events.tables.review_status',
    value: ReviewStatus.ConflictOfInterest,
    label: 'events.reviews.statuses.conflictofinterest',
    types: [FormType.Review],
  },
  {
    typeLabel: 'events.tables.review_status',
    value: ReviewStatus.Completed,
    label: 'events.reviews.statuses.completed',
    types: [FormType.Review],
  },
];

export const FlagFilters: MetaDataFilter[] = [
  {
    typeLabel: 'events.tables.flags',
    value: FlagFilter.UnpaidItems,
    label: 'events.tables.flags_items.unpaid_items',
    types: [FormType.Registration, FormType.Submission],
  },
  {
    typeLabel: 'events.tables.flags',
    value: FlagFilter.IsMissingRequiredFields,
    label: 'events.tables.flags_items.is_missing_required_fields',
    types: [FormType.Registration, FormType.Submission],
  },
  {
    typeLabel: 'events.tables.flags',
    value: FlagFilter.NotRegistered,
    label: 'events.tables.flags_items.not_registered',
    types: [FormType.Submission],
  },
  {
    typeLabel: 'events.tables.flags',
    value: FlagFilter.PendingPayment,
    label: 'events.tables.flags_items.pending_payment',
    types: [FormType.Registration, FormType.Submission],
  },
  {
    typeLabel: 'events.tables.flags',
    value: FlagFilter.InConflict,
    label: 'events.tables.flags_items.conflict',
    types: [FormType.Submission],
  },
];

export const StatusFilters: MetaDataFilter[] = [
  {
    typeLabel: 'events.tables.status',
    value: StatusFilter.Registered,
    label: `events.tables.status_items.${FormEntryStatus.Registered}`,
    types: [FormType.Registration],
  },
  {
    typeLabel: 'events.tables.status',
    value: StatusFilter.Submitted,
    label: `events.tables.status_items.${FormEntryStatus.Submitted}`,
    types: [FormType.Submission],
  },
  {
    typeLabel: 'events.tables.status',
    value: StatusFilter.OnWaitlist,
    label: `events.tables.status_items.${FormEntryStatus.OnWaitingList}`,
    types: [FormType.Registration],
  },
  {
    typeLabel: 'events.tables.status',
    value: StatusFilter.Withdrawn,
    label: `events.tables.status_items.${FormEntryStatus.Withdrawn}`,
    types: [FormType.Registration, FormType.Submission],
  },
  {
    typeLabel: 'events.tables.status',
    value: StatusFilter.Scheduled,
    label: `events.tables.status_items.${FormEntryStatus.Scheduled}`,
    types: [FormType.Submission],
  },
];

export const MetaDataFilters = ReviewStatusFilters.concat(FlagFilters);

export function toURLSearchParams(model: GetFormEntriesModel): URLSearchParams {
  const query = new URLSearchParams(model as any);

  // Deleting (avoid empty array in backend)
  query.delete('formFieldChoiceIds');
  query.delete('assignedFormFieldIds');
  query.delete('unassignedFormFieldIds');
  query.delete('subsetIds');
  query.delete('excludedIds');
  query.delete('sessionIds');
  query.delete('reviewStatusFilters');
  query.delete('flagFilters');
  query.delete('statusFilters');
  query.delete('withSubmissionFilters');
  query.delete('submissionDecisionsFilter');

  // URLSearchParams do not handles array, ex: foo=bar1&foo=bar2

  if (Array.isArray(model.formFieldChoiceIds)) {
    model.formFieldChoiceIds.forEach(x => query.append('formFieldChoiceIds', x));
  }
  if (Array.isArray(model.assignedFormFieldIds)) {
    model.assignedFormFieldIds.forEach(x => query.append('assignedFormFieldIds', x));
  }
  if (Array.isArray(model.unassignedFormFieldIds)) {
    model.unassignedFormFieldIds.forEach(x => query.append('unassignedFormFieldIds', x));
  }
  if (Array.isArray(model.reviewStatusFilters)) {
    model.reviewStatusFilters.forEach(x => query.append('reviewStatusFilters', x));
  }
  if (Array.isArray(model.flagFilters)) {
    model.flagFilters.forEach(x => query.append('flagFilters', x));
  }
  if (Array.isArray(model.statusFilters)) {
    model.statusFilters.forEach(x => query.append('statusFilters', x));
  }
  if (Array.isArray(model.withSubmissionFilters)) {
    model.withSubmissionFilters.forEach(x => query.append('withSubmissionFilters', x));
  }
  if (Array.isArray(model.sessionIds)) {
    model.sessionIds.forEach(x => query.append('sessionIds', x));
  }
  if (Array.isArray(model.submissionDecisionsFilter)) {
    model.submissionDecisionsFilter.forEach(x => query.append('submissionDecisionsFilter', x));
  }
  if (Array.isArray(model.subsetIds)) {
    model.subsetIds.forEach(x => query.append('subsetIds', x));
  }
  if (Array.isArray(model.excludedIds)) {
    model.excludedIds.forEach(x => query.append('excludedIds', x));
  }

  return query;
}
