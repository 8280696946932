import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=d022f97e"
import script from "./Spinner.vue?vue&type=script&lang=ts"
export * from "./Spinner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../nuxt-app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports