import { Component, Prop, mixins } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import { TranslateResult } from '../../../../types/i18n';
import InputValidationMixin from '../InputValidationMixin';

@Component
export default class InputTextMixin extends mixins(InputValidationMixin) {
  @Prop({ default: '' }) readonly label!: string | TranslateResult;
  @Prop({ default: Object }) readonly validation!: object | string | null;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly autofocus!: boolean;
  @Prop({ type: String }) readonly autocomplete!: string;
  @Prop({ type: String }) readonly placeHolder!: string | TranslateResult;
  @Prop({ type: Number }) readonly maxLength!: number;
  @Prop({ type: String }) readonly help!: string | TranslateResult;
  @Prop({ type: String }) readonly hint!: string | TranslateResult;

  get inputId() {
    return this.name ? this.name : uuid();
  }
}
