var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.presentationTimesType === _vm.PresentationTimesType.None)?_c('div',{staticClass:"presentation-index"},_vm._l((_vm.presentations),function(presentation,index){return _c('Transition',{key:presentation.id,attrs:{"name":"presentation-list__transition","mode":"out-in","appear":""}},[_c('PresentationCard',_vm._b({key:presentation.id,class:{ 'presentation-index__card -live': _vm.isLiveApp, 'presentation-index__card': !_vm.isLiveApp },style:({ '--transition-delay': `${index * 60}ms` }),attrs:{"form":_vm.submissionForm,"layout-mode":_vm.LayoutModes.list},on:{"toggle-vote":function($event){return _vm.$emit('toggle-vote', presentation)},"toggle-bookmark":function($event){return _vm.$emit('toggle-bookmark', presentation)}}},'PresentationCard',{
        presentation,
        isParticipant: _vm.isParticipant,
        isOrganizer: _vm.isOrganizer,
        linkName: _vm.linkName,
      },false))],1)}),1):_c('div',{staticClass:"timeline"},_vm._l((_vm.presentations),function(presentation,index){return _c('div',{key:presentation.id,staticClass:"timeline__row"},[_c('div',{staticClass:"timeline__side"},[_c('span',{staticClass:"timeline__time"},[_vm._v("\n        "+_vm._s(_vm.getTimeFromTimeGroup(presentation.time))+"\n      ")]),_vm._v(" "),_vm._m(0,true)]),_vm._v(" "),_c('div',{staticClass:"timeline__main"},[_c('Transition',{key:presentation.id,attrs:{"name":"timeline__transition","mode":"out-in","appear":""}},[_c('PresentationCard',_vm._b({key:presentation.id,staticClass:"timeline__card",style:({ '--transition-delay': `${index * 60 + 75}ms` }),attrs:{"title":presentation.id,"form":_vm.submissionForm,"layout-mode":_vm.LayoutModes.list},on:{"toggle-vote":function($event){return _vm.$emit('toggle-vote', presentation)},"toggle-bookmark":function($event){return _vm.$emit('toggle-bookmark', presentation)}}},'PresentationCard',{
            presentation,
            isParticipant: _vm.isParticipant,
            isOrganizer: _vm.isOrganizer,
            linkName: _vm.linkName,
          },false))],1)],1)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"timeline__decorator"},[_c('span',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"circle"})])
}]

export { render, staticRenderFns }