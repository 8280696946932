
import { Component, Prop, Emit, Model, Inject, Watch, mixins } from 'nuxt-property-decorator';
import InputCheckbox from '../design-system/input/input-checkbox/InputCheckbox.vue';
import InputText from '../design-system/input/input-text/InputText.vue';
import InputSelectCountry from '../design-system/input/input-select/InputSelectCountry.vue';
import { BillingInfoDto, CivicAddressDto } from '../../services';
import { isObject } from '../../helpers';
import { CivicAddressMixins } from '../../mixins';

@Component({
  components: {
    InputCheckbox,
    InputText,
    InputSelectCountry,
  },
})
export default class BillingInfoFieldset extends mixins(CivicAddressMixins) {
  @Prop({ type: Object, default: null }) readonly providedBillingInfo!: BillingInfoDto;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ type: String, default: 'md' }) readonly listSize!: string;

  @Model('input', { validator: isObject, required: true }) readonly value!: BillingInfoDto;

  @Emit('input') emitInput(_value: BillingInfoDto) {}

  @Emit() countryOrStateChange() {}

  @Inject() $validator;

  useProvidedBillingInfo = true;

  get addressOutput() {
    return this.getCivicAddressOutput(this.value.civicAddress);
  }

  @Watch('useProvidedBillingInfo', { immediate: true })
  onUseProvidedBillingInfoChange(isActive: boolean, wasActive: boolean) {
    if (isActive === wasActive) return;

    if (isActive) {
      this.emitInput({ ...this.value, ...this.providedBillingInfo });
    } else if (wasActive != null) {
      this.emitInput({
        ...this.value,
        ...{
          name: '',
          civicAddress: { addressLine1: '', city: '', countryRegion: '', stateProvince: '', postalCode: '' },
        },
      });
    }
  }

  public onNameChange(value: string) {
    this.emitInput({ ...this.value, name: value });
  }

  public onAddressChange(value: Partial<CivicAddressDto>) {
    this.emitInput({ ...this.value, civicAddress: { ...this.value.civicAddress, ...value } });
    if (value.countryRegion || value.stateProvince) this.countryOrStateChange();
  }
}
