
import { Component, Vue, Inject, Prop, Watch } from 'nuxt-property-decorator';
import { BillingInfoDto, EventDto } from '../../services';
import InputText from '../design-system/input/input-text/InputText.vue';

@Component({
  components: { InputText },
})
export default class MonerisCreditCard extends Vue {
  @Inject() $validator;
  @Prop({ required: true }) billingInfo!: BillingInfoDto;
  @Prop({ required: false }) readonly nameOnCard!: string | null;
  @Prop({ required: true }) event!: EventDto;

  localNameOnCard = '';

  get origin() {
    return this.event.monerisEnvironment === 'TEST' ? 'https://esqa.moneris.com' : 'https://www3.moneris.com';
  }

  get cssParams() {
    if (this.$device.onlyPhone) {
      return {
        css_body:
          'margin:.5rem;max-width:100%;display:grid;grid-template-rows:repeat(6,auto);grid-template-columns:repeat(2,minmax(0, 1fr))',
        css_label_pan: 'grid-column:1/-1;grid-row:1/2;',
        css_label_exp: 'grid-column:1/-1;grid-row:3/4;',
        css_label_cvd: 'grid-column:1/-1;grid-row:5/6;',
        css_textbox_pan: 'grid-column:1/-1;grid-row:2/3;margin-bottom:1.25rem;',
        css_textbox_exp: 'grid-column:1/-1;grid-row:4/5;margin-bottom:1.25rem;',
        css_textbox_cvd: 'grid-column:1/-1;grid-row:6/7;',
      };
    }
    return {
      css_body:
        'margin:.5rem;max-width:100%;display:grid;column-gap:1.25rem;grid-template-rows:repeat(4,auto);grid-template-columns:repeat(2,minmax(0, 1fr))',
      css_label_pan: 'grid-column:1/3;grid-row:1/2;',
      css_label_exp: 'grid-column:1/2;grid-row:3/4;',
      css_label_cvd: 'grid-column:2/3;grid-row:3/4;',
      css_textbox_pan: 'grid-column:1/3;grid-row:2/3;margin-bottom:1.25rem;',
      css_textbox_exp: 'grid-column:1/2;grid-row:4/5;',
      css_textbox_cvd: 'grid-column:2/3;grid-row:4/5;',
    };
  }

  get iframeSrc(): string {
    if (!this.event.monerisTokenizationId) return '';
    const defaultParams = {
      id: this.event.monerisTokenizationId!,
      pmmsg: '1',
      css_textbox:
        'border: 1px solid #e4e8ef;border-radius:.5rem;font-size:1rem;line-height:1.5;padding: 0.5rem 1rem;box-shadow: 0 0 0 #b7c2ff; height: 2.75rem;font-family:satoshi,helvetica,arial,sans-serif;',
      enable_cc_formatting: '1',
      enable_exp: '1',
      enable_exp_formatting: '1',
      pan_label: this.$t('shared.billing.credit_card.number'),
      exp_label: this.$t('shared.billing.credit_card.expiry_moneris'),
      enable_cvd: '1',
      cvd_label: this.$t('shared.billing.credit_card.cvv'),
      display_labels: '1',
      css_input_label:
        'font-size:.875rem;line-height:1.42;color: #344054;margin-bottom:.5rem;font-family:satoshi,helvetica,arial,sans-serif;',
    };
    const params = new URLSearchParams({ ...defaultParams, ...this.cssParams } as Record<string, string>).toString();
    return `${this.origin}/HPPtoken/index.php?${params}`;
  }

  public updateErrorMessage(codes?: string[] | null) {
    let message = '';
    if (codes) {
      message += codes
        .map(code => this.$t(`shared.error.server_validation.moneris.tokenization.${code}`))
        .join('<br/>');
    }
    const displayError = document.getElementById('card-errors');
    if (displayError) displayError.innerHTML = message;
  }

  public initiatePayment(): Promise<string | null> {
    this.updateErrorMessage();

    return new Promise(resolve => {
      if (!this.billingInfo || !this.billingInfo.civicAddress) return resolve(null);

      const onMessage = ((messageEvent: MessageEvent) => {
        if (messageEvent.origin !== this.origin) return; // These are not the events you're looking for.
        cleanup();

        try {
          const respData = JSON.parse(messageEvent.data);

          if (!respData.responseCode.includes('001')) {
            this.updateErrorMessage(respData.responseCode);
            return resolve(null);
          }

          resolve(respData.dataKey);
        } catch (_) {
          this.updateErrorMessage(['tokenization_error']);
          resolve(null);
        }
      }) as EventListener;

      const cleanup = () => {
        window.removeEventListener('message', onMessage);
      };

      // Trigger tokenization
      const monerisIframeRef = (document.getElementById('monerisFrame') as HTMLIFrameElement)?.contentWindow;

      if (!monerisIframeRef) return resolve(null);
      window.addEventListener('message', onMessage);
      monerisIframeRef.postMessage('tokenize', `${this.origin}/HPPtoken/index.php`);
    });
  }

  @Watch('localNameOnCard')
  onLocalNameOnCardUpdated(nameOnCard: string) {
    this.$emit('nameOnCardUpdate', nameOnCard);
  }

  mounted() {
    this.localNameOnCard = this.nameOnCard || '';
  }
}
