import moment from 'moment-timezone';
import { isUtcNowInRange } from '../../../helpers';
import { SchedulePresentationForFormEntryDto } from './SchedulePresentationForFormEntryDto';
import { TranslatableField } from './TranslatableField';

export enum LiveStreamType {
  None = 'none',
  VirtualPosterSession = 'virtualpostersession',
  External = 'external',
  ZoomEmbed = 'zoomembed',
}

export enum PresentationTimesType {
  None = 'none',
  Equal = 'equal',
  Unequal = 'unequal',
}

export interface ScheduleTrackDto {
  sessionId: string;
  trackId: string;
}

export interface RecordedVideoDto {
  id: string;
  url: string;
}

export interface ScheduleDto {
  id: string;
  title: TranslatableField;
  description: TranslatableField;
  startDate: string;
  endDate: string;
  recordedVideo: RecordedVideoDto | null;
  presentationTimesType: PresentationTimesType;
  roomId: string | null;
  isVPS: boolean;
  creationDate: string;
  isBookmarkedByCurrentUser: boolean;
  presentationCount: number;
  sessionTracks: ScheduleTrackDto[];
}

export interface RoomDto {
  id: string;
  name: TranslatableField;
  liveStreamType: LiveStreamType;
  externalLink: string;
  zoomMeetingNumber: string | null;
  zoomMeetingPassword: string | null;
}

export interface TrackDto {
  id: string;
  name: TranslatableField;
  color: string;
}

export function isVPSOpen(schedules: ScheduleDto[], datetime: string): boolean {
  if (!schedules || schedules.length === 0) return false;

  return schedules
    .filter(schedule => schedule.isVPS)
    .some(schedule => isUtcNowInRange(schedule.startDate, schedule.endDate, '[]', datetime));
}

export function nextVpsStartDate(
  schedules: ScheduleDto[],
  presentations: SchedulePresentationForFormEntryDto[] | null,
): moment.Moment | null {
  if (!schedules?.length) return null;

  const nextVpsDates = schedules
    .filter(schedule => schedule.isVPS)
    .filter(schedule => (schedule.presentationCount ? presentations?.some(p => p.sessionId === schedule.id) : true))
    .flatMap(schedule =>
      moment(
        schedule.presentationTimesType !== PresentationTimesType.None
          ? (presentations?.find(p => p.sessionId === schedule.id)?.startDate ?? schedule.startDate)
          : schedule.startDate,
      ),
    )
    .filter(date => date.isSameOrAfter(moment()))
    .sort((a, b) => (a.isSameOrAfter(b) ? 1 : -1));

  if (!nextVpsDates?.length) return null;
  return nextVpsDates[0];
}
