
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import HeaderMixin from './HeaderMixin';

@Component({})
export default class Header extends mixins(HeaderMixin) {
  @Prop({ type: String, default: null }) readonly title!: string | null;
  @Prop({ type: String, default: null }) readonly description!: string | null;
  @Prop(Boolean) readonly formPage!: boolean;
  @Prop(Boolean) readonly schedulePage!: boolean;
}
