
import { Component, Vue, Prop, Ref, namespace } from 'nuxt-property-decorator';
import { TranslateResult } from '../../types/i18n';
import { ScheduleDto, RoomDto, LiveStreamType, isSuccess, PageDto, PageType } from '../../services';
import { Collapsible, RawHtml } from '../layout';
import RoomCta from '../blocks/RoomCta.vue';
import UserActionButton from '../blocks/UserActionButton.vue';
import SessionPresentationCount from './SessionPresentationCount.vue';
import SessionTrackList from './SessionTrackList.vue';
import SessionVideoModal from './SessionVideoModal.vue';

const EventsModule = namespace('events');

@Component({
  components: {
    Collapsible,
    RawHtml,
    RoomCta,
    UserActionButton,
    SessionPresentationCount,
    SessionTrackList,
    SessionVideoModal,
  },
})
export default class SessionCard extends Vue {
  @EventsModule.State pages!: PageDto[];
  @EventsModule.Mutation updateSession!: (session: ScheduleDto) => Promise<boolean>;

  @Prop({ required: true }) readonly liveSessions!: ScheduleDto[];
  @Prop({ required: true }) session!: ScheduleDto;
  @Prop({ required: true }) readonly currentTime!: string;
  @Prop({ required: true }) path!: any;
  @Prop({ required: false }) room!: RoomDto;
  @Prop({ required: true }) readonly timezone!: string;
  @Prop(Boolean) isBookmarkable!: boolean;
  @Prop(Boolean) readonly isLiveApp!: boolean;

  @Ref() videoModal?: SessionVideoModal;

  get isLive() {
    return this.session ? this.liveSessions.some(({ id }) => id === this.session.id) : false;
  }

  get isVirtual() {
    return !this.room ? false : this.room.liveStreamType !== LiveStreamType.None;
  }

  get isVps() {
    return !this.room ? false : this.room.liveStreamType === LiveStreamType.VirtualPosterSession;
  }

  get isOver() {
    if (!this.session) return false;
    const now = this.$moment(this.currentTime);
    return now.isSameOrAfter(this.$moment(this.session.endDate));
  }

  get hasRoomCta() {
    if (this.isVirtual) {
      if (this.isLive || !!this.countDown) return true;
    }
    return false;
  }

  get hasPresentationCount() {
    return this.pages.some(({ type }) => type === PageType.Abstracts) && this.session?.presentationCount;
  }

  get countDown(): TranslateResult | null {
    if (this.isOver || this.isLive) return null;
    const normalizedStartDate = this.$moment(this.session.startDate);
    const now = this.$moment(this.currentTime);
    const minute = normalizedStartDate.diff(now, 'minutes');
    if (minute > 0 && minute <= 15) return this.$tc('shared.sessions.minute_countdown', minute);
    if (minute === 0) return this.$t('shared.sessions.less_than_a_minute');
    return null;
  }

  get hasVideo() {
    return !!this.session.recordedVideo?.url;
  }

  get hasFooter() {
    return !!(this.hasPresentationCount || this.hasRoomCta || this.isLive || this.countDown || this.hasVideo);
  }

  get ariaLabel(): TranslateResult {
    return !this.session.isBookmarkedByCurrentUser
      ? `${this.$t('shared.sessions.bookmark')} ${this.transl8(this.session.title)}`
      : `${this.$t('shared.sessions.unbookmark')} ${this.transl8(this.session.title)}`;
  }

  get sessionTimeSlot() {
    return `${this.getHour(this.session.startDate)} - ${this.getHour(this.session.endDate)} ${this.$moment
      .tz(this.session.startDate, this.timezone)
      .format('z')}`;
  }

  get strippedDescription() {
    return this.stripHtml(this.transl8(this.session.description));
  }

  public getHour(datetime: string): string {
    return this.$moment(datetime).format('LT');
  }

  public async toggleBookmark(isToggled: boolean) {
    const result = isToggled
      ? await this.$api.bookmarkSession(this.session.id)
      : await this.$api.unbookmarkSession(this.session.id);

    if (isSuccess(result)) {
      this.updateSession({ ...this.session, isBookmarkedByCurrentUser: isToggled });
    }
  }

  public openVideoModal() {
    this.videoModal?.openSessionVideo();
  }
}
