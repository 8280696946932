import { pick } from 'lodash-es';
import { OrganizationCurrency } from './OrganizationDto';

export enum PackageTier {
  Free = 'free',
  Essentials = 'essentials',
  Advanced = 'advanced',
  Professional = 'professional',
  Custom = 'custom',
}

export interface PackageDto {
  id: string;
  cost: number;
  currency: OrganizationCurrency;
  fee: number;
  tier: PackageTier;
  version: string;
  submissionCapacity: number;
}

export interface PackageOption {
  id: string;
  tier: PackageTier;
  cost: number;
  fee: number;
  disabled: boolean;
  current: boolean;
}

export function preparePackageOption(pck: PackageDto, eventActivePackage?: PackageDto): PackageOption {
  const eventActiveTier: PackageTier | null = eventActivePackage?.tier || null;
  const isCurrent = eventActiveTier === pck.tier;
  let isDisabled = false;
  if (
    (eventActiveTier === PackageTier.Advanced && pck.tier === PackageTier.Essentials) ||
    (eventActiveTier === PackageTier.Professional && pck.tier !== PackageTier.Professional)
  ) {
    isDisabled = true;
  }
  return {
    ...pick(pck, ['id', 'tier', 'cost', 'fee']),
    disabled: isCurrent || isDisabled,
    current: isCurrent,
  };
}
