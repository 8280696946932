export default {
  acronym: 'Q&A',
  title: 'Ask a question',
  edit: 'Edit',
  delete: 'Delete',
  presenter: 'Presenter',
  co_author: 'Co-author',
  submitter: 'Submitter',
  reply_btn: 'Reply',
  upvote: 'upvotes | upvote | upvotes',
  edit_btn: 'Edit',
  cancel_btn: 'Cancel',
  submit_btn: 'Submit',
  textarea_message_placeholder: 'Your question',
  textarea_reply_placeholder: 'Your reply',
  textarea_placeholder: 'Ask a question',
  success_message: {
    added: 'Comment added',
    updated: 'Comment edited',
    deleted: 'Comment deleted',
    delete_all: 'The questions were successfully removed',
  },
  must_be_logged_in: 'You must be logged in to interact.',
  clear_messages: 'Clear all questions',
  clear_messages_confirm: 'Do you really want to clear all questions?',
  no_results: 'Type a question for the presenter.',
};
