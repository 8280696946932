
import { Component, Vue, Ref, Prop } from 'nuxt-property-decorator';
import { EventDto } from '../../services';
import { FORM_CONTEXT } from '../../types/cookies';

@Component({})
export default class TouchNetCreditCard extends Vue {
  @Ref() readonly form!: HTMLFormElement;
  @Prop({ required: true }) event!: EventDto;

  ticket: string | null = '';

  mounted() {
    this.$nuxt.$on('initTouchNetRedirect', this.onInitTouchNetRedirect);
  }

  beforeDestroy() {
    this.$nuxt.$off('initTouchNetRedirect', this.onInitTouchNetRedirect);
  }

  public async onInitTouchNetRedirect(
    ticket: string,
    context: string,
    forceRedirectPath: string | null = null,
  ): Promise<void> {
    this.ticket = ticket;

    const expires = this.$moment().add(3, 'days').toDate();
    const path = '/';
    const params: any = this.$config.API_URL?.includes('localhost')
      ? { domain: 'localhost', expires, path }
      : { domain: '.fourwaves.com', expires, path, sameSite: 'none', secure: true };

    localStorage.setItem(FORM_CONTEXT, context);
    if (forceRedirectPath) {
      this.$cookies.set('external_payment_redirect', `${window.location.origin}${forceRedirectPath}`, params);
    } else {
      this.$cookies.set('external_payment_redirect', `${window.location.origin}${this.$route.fullPath}`, params);
    }

    await this.$nextTick();
    this.form.submit();
  }
}
