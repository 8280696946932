import Vue from 'vue';
import { IVueI18n } from 'vue-i18n';

type Moment = typeof import('~/../moment');

// Handle language switch side effects.
// This is exported for testing purpose.
export const languageSwitched = function ({
  locale,
  $moment,
}: {
  locale: string;
  // There is a (small) chance that `$moment` is `undefined`
  // in the early stage of the application.
  $moment: Moment | undefined;
}): void {
  $moment?.locale(locale);
};

// Plugin used to hangle language switch side effects.
// This plugin must be executed on `client` side only.
export default (context: { app: Vue; i18n: IVueI18n }) => {
  const { app, i18n } = context;
  i18n.onLanguageSwitched = (_, locale) => {
    // We must get the reference of `$moment` here because
    // it is not available at the time of plugin execution.
    const { $moment } = app;
    languageSwitched({ locale, $moment });
  };
};
