
import { Component, Watch, Vue, namespace } from 'nuxt-property-decorator';
import { Route } from 'vue-router';
import { EventDto, EventLanguage, hexToHSL } from 'fourwaves-shared';
import Footer from '~/components/layout/Footer.vue';
import Menu from '~/components/layout/Menu.vue';
import Header from '~/components/layout/Header.vue';
import BrowserWarning from '~/components/layout/BrowserWarning.vue';
import LiveBanner from '~/components/layout/LiveBanner.vue';
import EventDetails from '~/components/layout/EventDetails.vue';
import FwBadge from '~/components/shared/FwBadge.vue';
import { NuxtEvents } from '~/core';

const EventsModule = namespace('events');
const HistoryModule = namespace('history');

@Component({
  components: {
    EventDetails,
    Menu,
    Footer,
    Header,
    FwBadge,
    BrowserWarning,
    LiveBanner,
  },
})
export default class DefaultLayout extends Vue {
  @HistoryModule.Mutation setPreviousRoute!: (route: Route | null) => void;

  @EventsModule.State language!: string;
  @EventsModule.State currentEvent!: EventDto;
  @EventsModule.State isWhiteLabel!: boolean;
  @EventsModule.Getter isLiveAppOpen!: boolean;

  public head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      bodyAttrs: {
        style: this.colorVariables,
      },
      titleTemplate: titleChunk => {
        if (!this.currentEvent) return '';
        return titleChunk
          ? `${titleChunk} - ${this.transl8(this.currentEvent.name)}`
          : this.transl8(this.currentEvent.name);
      },
      meta: this.currentEvent?.isPrivate
        ? [{ hid: 'robots', name: 'robots', content: 'noindex' }]
        : this.currentEvent
          ? [
              // Global
              {
                name: 'robots',
                content:
                  this.$config.EVENT_WEBSITE_URL.includes('test-event') ||
                  this.$config.EVENT_WEBSITE_URL.includes('staging-event')
                    ? 'noindex, nofollow'
                    : 'follow, index',
              },
              { name: 'description', content: `Fourwaves - ${this.transl8(this.currentEvent.name)}` },
              { name: 'keywords', content: `Fourwaves, ${this.transl8(this.currentEvent.name)}` },

              // Twitter
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:site', content: '@fourwavesco' },
              { name: 'twitter:title', content: this.transl8(this.currentEvent.name) },
              {
                name: 'twitter:image',
                content: this.transl8(this.currentEvent.banner) ? this.transl8(this.currentEvent.banner) : null,
              },
              { name: 'twitter:label1', content: 'When' },
              { name: 'twitter:data1', content: this.$moment(this.currentEvent.startDate).format('LL') },

              // LinkedIn
              {
                name: 'image',
                property: 'og:image',
                content: this.transl8(this.currentEvent.banner) ? this.transl8(this.currentEvent.banner) : null,
              },

              // Open Graph
              { name: 'og:site_name', content: 'Fourwaves' },
              {
                name: 'og:image',
                content: this.transl8(this.currentEvent.banner) ? this.transl8(this.currentEvent.banner) : null,
              },
              { name: 'og:title', content: this.transl8(this.currentEvent.name) },
              {
                name: 'og:url',
                content:
                  this.$route.path?.endsWith('/pages') || this.$route.path?.endsWith('/pages/')
                    ? `${this.$config.EVENT_WEBSITE_URL}${this.$route?.path.replace('/pages', '')}`
                    : `${this.$config.EVENT_WEBSITE_URL}${this.$route?.path}`,
              },
              { name: 'og:type', content: 'events.event' },
              { name: 'og:ttl', content: '777600' },
              { name: 'event:start_time', content: this.currentEvent.startDate },
              { name: 'event:end_time', content: this.currentEvent.endDate },
            ]
          : [],
      link: [
        {
          rel: 'canonical',
          href:
            this.$route.path?.endsWith('/pages') || this.$route.path?.endsWith('/pages/')
              ? `${this.$config.EVENT_WEBSITE_URL}${this.$route?.path.replace('/pages', '')}`
              : `${this.$config.EVENT_WEBSITE_URL}${this.$route?.path}`,
        },
      ],
    };
  }

  get colorVariables() {
    return !this.currentEvent
      ? ''
      : `
      --main-color: #${this.currentEvent?.primaryColor};
      --main-color-hsl: ${hexToHSL(`${this.currentEvent?.primaryColor}`)};
    `;
  }

  get isRoomPage(): boolean {
    return this.$route?.name?.includes('rooms') ?? false;
  }

  get isParticipantPage(): boolean {
    return this.$route?.name?.includes('participants') ?? false;
  }

  get isFormPage() {
    return (this.$route?.name?.includes('registration') || this.$route?.name?.includes('submission')) ?? false;
  }

  get mainClassNames() {
    return { 'util--bg-gray': this.isFormPage };
  }

  @Watch('$route', { immediate: true })
  onRouteChanged(_: Route, previousRoute?: Route | null) {
    this.setPreviousRoute(previousRoute || null);
  }

  created() {
    this.$moment.tz.setDefault(this.currentEvent?.timeZone);
    this.handleWebsiteLanguage();
    this.enforceUserLoginWhenNeeded();
  }

  beforeDestroy() {
    this.$nuxt.$off(NuxtEvents.ChangeLanguage);
  }

  public handleWebsiteLanguage() {
    this.$moment.locale(this.$i18n.locale);
    this.$nuxt.$on(NuxtEvents.ChangeLanguage, this.changeLanguage);
    if (this.language === EventLanguage.French) this.changeLanguage('fr');
    else if (this.language === EventLanguage.English) this.changeLanguage('en');
  }

  public enforceUserLoginWhenNeeded() {
    if (!this.$route?.query?.email) return;

    if (!this.$auth.loggedIn) {
      const loginUrl = this.getLoginUrl(this.$route.query.email as string);
      if (process.client) window.location.assign(loginUrl);
      return;
    }

    const query = Object.assign({}, this.$route.query);
    delete query.email;
    this.$router.replace({ query });
  }

  public changeLanguage(code: 'fr' | 'en') {
    if (this.$i18n.locale === code) return;
    this.$router.push(this.switchLocalePath(code));
  }
}
