export default {
  credit_card: {
    number: 'Card number',
    name_on_card: 'Name on card',
    expiry: 'Expiry Date',
    expiry_paypal: 'Expiry Date (MM/YYYY)',
    expiry_moneris: 'Expiry Date (MM/YY)',
    cvv: 'CVV',
  },
  billing_info: {
    title: 'Billing Information',
    fullname: 'Invoice To (Person’s Name and/or Company)',
    address_line_1: 'Street Address',
    city: 'City',
    postal_code: 'Postal / Zip',
    use_org: 'Use organization address',
  },
  paypal: {
    billing_info_notice: 'Please fill in your billing information.',
    pay: 'Pay with PayPal',
  },
};
